import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import { BiSolidHide, BiSolidShow } from "react-icons/bi";
import axios from "axios";
import ResetSuccessModal from "./SuccessModal";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();

  const [message, setMessage] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSuccessModal = () => {
    setShowSuccessModal(true);
  };

  const [formData, setFormData] = useState({
    email: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    } catch (error) {
      console.error("handle-change error", error);
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (formData.newPassword !== formData.confirmPassword) {
        setMessage("Passwords don't match. Please check and try again.");
        return;
      }

      const token = localStorage.getItem("reset-token");
      const email = formData.email;
      const newPassword = formData.newPassword;

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/reset-password`, {
        email,
        token,
        newPassword,
      });
      handleSuccessModal();
    } catch (error) {
      console.log("reset error", error);
      setMessage("Password reset failed");
    }
  };

  const btn = {
    "--cui-btn-bg": "#0e3f6a",
    "--cui-btn-color": "white",
    "--cui-btn-hover-bg": "#3c97cb",
    "--cui-btn-active-bg": "#0e3f6a",
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit}>
                    <h2>Reset Password</h2>
                    <p className="text-medium-emphasis">
                      Set your new password
                    </p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText
                        style={{ backgroundColor: "#0e3f6a", color: "white" }}
                      >
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        type="email"
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                      />
                    </CInputGroup>
                    <div style={{ position: "relative", width: "100%" }}>
                      <CInputGroup className="mb-4">
                        <CInputGroupText style={{ backgroundColor: "#0e3f6a", color: "white" }}>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <CFormInput
                          type={showPassword ? "text" : "password"}
                          name="newPassword"
                          placeholder="Password"
                          onChange={handleChange}
                          style={{ paddingRight: "2.5rem" }}
                        />
                        <span style={{
                          fontSize: "20px",
                          position: "absolute",
                          right: "1rem",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer"
                        }} onClick={togglePasswordVisibility}>
                          {showPassword ? <span><BiSolidHide /></span> : <span><BiSolidShow /></span>}
                        </span>
                      </CInputGroup>
                    </div>

                    <div style={{ position: "relative", width: "100%" }}>
                      <CInputGroup className="mb-4">
                        <CInputGroupText style={{ backgroundColor: "#0e3f6a", color: "white" }}>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <CFormInput
                          type={showConfirmPassword ? "text" : "password"}
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          onChange={handleChange}
                          style={{ paddingRight: "2.5rem" }}
                        />
                        <span style={{
                          fontSize: "20px",
                          position: "absolute",
                          right: "1rem",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer"
                        }} onClick={toggleConfirmPasswordVisibility}>
                          {showConfirmPassword ? <span><BiSolidHide /></span> : <span><BiSolidShow /></span>}
                        </span>
                      </CInputGroup>
                    </div>
                    {message && <p style={{ color: "red" }}>{message}</p>}
                    <CRow>
                      <CCol xs={6}>
                        <CButton style={btn} className="px-4" type="submit">
                          Submit
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
      <ResetSuccessModal
        show={showSuccessModal}
        handleClose={() => setShowSuccessModal(false)}
        handleConfirm={() => navigate("/login")}
      />
    </div>
  );
};

export default ResetPassword;
