import React, { useEffect, useState } from "react";
import AppSidebar from "../../../components/AppSidebar";
import {
    CContainer,
    CHeader,
    CHeaderNav,
    CHeaderToggler,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow, CPagination, CPaginationItem, CSpinner
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import '../../styling/Pagination.css';
import { useNavigate } from "react-router-dom";
import '../../styling/Table.css';

const MySubs1 = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const sidebarShow = useSelector((state) => state?.sidebarShow);

    const [uniSubs, setUniSubs] = useState([]);
    const [flag, setFlag] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const parseUserFromLocalStorage = () => {
        try {
            const user = localStorage.getItem("user");
            if (!user) {
                window.location.replace('/login');
                return;
            }
            const parsedUser = JSON.parse(user);
            return parsedUser;
        } catch (error) {
            console.error("Error parsing user from localStorage:", error);
            return null;
        }
    };

    const user = parseUserFromLocalStorage();
    const { email, token, user_role, university_id } = user;

    useEffect(() => {
        const navGroup = document.querySelector(".nav-group");
        navGroup.classList.add("show");

        const getSubs = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-uni-subs/${university_id}`, {
                    user: {
                        email: email,
                        user_role: user_role
                    },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUniSubs(response?.data?.data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching subscriptions:", error);
            }
        };

        if (user) {
            getSubs();
            setFlag(false);
        }
    }, [flag]);

    const [pageNumber, setPageNumber] = useState(0);
    const itemsPerPage = 5;
    const pageVisited = pageNumber * itemsPerPage;
    const displayUniSubs = uniSubs.slice(pageVisited, pageVisited + itemsPerPage);
    const pageCount = Math.ceil(uniSubs.length / itemsPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    }

    const tableHeader = {
        '--cui-table-bg': "#0e3f6a",
        '--cui-table-color': "white"
    }

    const paginationActive = {
        '--cui-pagination-active-bg': "# 3c97cb"
    }

    return (
        <div>
            <AppSidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <CHeader className="d-flex">
                    <CContainer>
                        <CHeaderToggler
                            className="ps-1"
                            onClick={() =>
                                dispatch({ type: "set", sidebarShow: !sidebarShow })
                            }
                        >
                            <CIcon icon={cilMenu} size="lg" />
                        </CHeaderToggler>
                        <CHeaderNav className="d-flex me-auto ms-3 mt-2">
                            <h4 style={{ marginRight: '3vw' }}>Harvard University</h4>
                        </CHeaderNav>
                        <CHeaderNav>
                        </CHeaderNav>
                    </CContainer>
                </CHeader>

                <div className="ms-4 mt-5 me-4">
                    <CTable striped>
                        <CTableHead style={tableHeader}>
                            <CTableRow>
                                <CTableHeaderCell className="text-center">
                                    Subscription Name
                                </CTableHeaderCell>
                                <CTableHeaderCell className="text-center">
                                    Start Date
                                </CTableHeaderCell>
                                <CTableHeaderCell className="text-center">
                                    End Date
                                </CTableHeaderCell>
                                <CTableHeaderCell className="text-center">
                                    Actions
                                </CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody style={{ padding: "5px" }}>
                            {isLoading ? <div style={{
                                marginTop: "10vh",
                                alignContent: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <CSpinner />
                            </div> : displayUniSubs.length === 0 ? <CTableRow>
                                <CTableDataCell className="text-center" colSpan="6">
                                    <span>No data to show</span>
                                </CTableDataCell>
                            </CTableRow> :
                                displayUniSubs.map((uni_subs, index) => (
                                    <CTableRow
                                        v-for="item in tableItems"
                                        key={index} >
                                        <CTableDataCell className="text-center">
                                            <div>
                                                <span>{uni_subs.subscription_name}</span>
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell className="text-center">
                                            <div>
                                                <span>{uni_subs.start_date}</span>
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell className="text-center">
                                            <div>
                                                <span>{uni_subs.end_date}</span>
                                            </div>
                                        </CTableDataCell>
                                        <CTableDataCell className="text-center">
                                            <div style={{ color: "blue", marginRight: "1vw", cursor: "pointer" }}
                                                onClick={(e) => navigate(`/subs-content?id=${uni_subs.university_subscription_mapping_id}`)}>
                                                View
                                            </div>
                                        </CTableDataCell>
                                    </CTableRow>
                                ))
                            }
                        </CTableBody>
                    </CTable>
                </div>
                {displayUniSubs.length > 0 &&
                    (<CPagination align="center" aria-label="Page navigation example" style={paginationActive}>
                        <CPaginationItem
                            aria-label="Previous"
                            onClick={() => setPageNumber(pageNumber - 1)}
                            disabled={pageNumber === 0}
                        >
                            <span aria-hidden="true">&laquo;</span>
                        </CPaginationItem>

                        {Array.from({ length: pageCount }, (_, index) => (
                            <CPaginationItem
                                key={index + 1}
                                active={pageNumber === index}
                                onClick={() => setPageNumber(index)}
                            >
                                {index + 1}
                            </CPaginationItem>
                        ))}

                        <CPaginationItem
                            aria-label="Next"
                            onClick={() => setPageNumber(pageNumber + 1)}
                            disabled={pageNumber === pageCount - 1}
                        >
                            <span aria-hidden="true">&raquo;</span>
                        </CPaginationItem>
                    </CPagination>)}
            </div>
        </div>
    );
};

export default MySubs1;
