import { createStore } from 'redux'

const initialState = {
  sidebarShow: true,
}

const changeState = (state = initialState, { type, ...rest }) => {
  try {
    if (!type || !state) {
      console.error("params not present");
      return;
    }

    switch (type) {
      case 'set':
        return { ...state, ...rest }
      default:
        return state
    }
  } catch (error) {
    console.error("store-error", error)
  }
}

const store = createStore(changeState)
export default store;
