import react, { useState, useEffect } from "react";
import {
    CForm,
    CCol,
    CHeader,
    CHeaderNav,
    CHeaderToggler,
    CContainer,
    CSpinner,
    CButton
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import AppSidebar from "../../../components/AppSidebar";
import ConfirmationModal from "../../confirmation/ConfirmationModal";
import EditProgram from "./EditProgram";
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { useSelector, useDispatch } from "react-redux";
import VideoPlayer from "../video/VideoPlayer";
import { FaRegCirclePlay } from "react-icons/fa6";
import { CiViewList } from "react-icons/ci";
import './Programs.css'
import { BreadCrumb } from 'primereact/breadcrumb';

const ProgramDetails = () => {
    const dispatch = useDispatch();
    const sidebarShow = useSelector((state) => state?.sidebarShow);
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('program_id');

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [programDetails, setProgramDetails] = useState([]);
    const [flag, setFlag] = useState(false);

    const [courseTreeTableData, setCourseTreeTableData] = useState([]);
    const [videoTreeTableData, setVideoTreeTableData] = useState([]);

    const [programCourse, setProgramCourse] = useState([]);
    const [programVideo, setProgramVideo] = useState([]);
    const [courseVideos, setCourseVideos] = useState([]);

    const parseUserFromLocalStorage = () => {
        try {
            const user = localStorage.getItem("user");
            if (!user) {
                window.location.replace('/login');
                return;
            }
            const parsedUser = JSON.parse(user);
            return parsedUser;
        } catch (error) {
            console.error("Error parsing user from localStorage:", error);
            return null;
        }
    };

    const user = parseUserFromLocalStorage();
    const { token } = user;

    useEffect(() => {
        const [navItem] = document.querySelectorAll("[href='/program-list']");
        navItem.classList.add("active");

        const navGroup = document.querySelector(".nav-group");
        navGroup.classList.add("show");

        const getCourseVideos = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-course-videos`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setCourseVideos(response?.data?.data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching course-videos:", error);
            }
        };

        const getProgramDetails = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-program-detail/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setProgramDetails(response?.data?.data[0]);
                setProgramCourse(response?.data?.courseResults);
                setProgramVideo(response?.data?.videoResults)
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching Program-details:", error);
            }
        };

        if (user) {
            getProgramDetails();
            getCourseVideos();
        }
        setFlag(false);
    }, [flag]);

    useEffect(() => {
        if (programCourse && programVideo && courseVideos) {
            setCourseTreeTableData(
                createCourseTreeTableData(programCourse, courseVideos)
            );
            setVideoTreeTableData(createVideoTreeTableData(programVideo));
        }
    }, [programCourse,
        programVideo,
        courseVideos,
    ]);

    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedProgram, setSelectedProgram] = useState(null);
    const [selectedProgramCourse, setSelectedProgramCourse] = useState(null);
    const [selectedProgramVideo, setSelectedProgramVideo] = useState(null);

    const updateProgram = async () => {
        setShowEditModal(true);
        setSelectedProgram(programDetails);
        setSelectedProgramCourse(programCourse);
        setSelectedProgramVideo(programVideo);
    }

    const handleEditProgramFlag = (editProgramFlag) => {
        setFlag(editProgramFlag);
    }

    const [ProgramDeleteConfirmModal, setProgramDeleteConfirmModal] = useState(false);

    const handleProgramDeleteConfirmation = (e, programId) => {
        setProgramDeleteConfirmModal(true);
        setSelectedProgram(programId);
    }

    const deleteProgram = async (e, programId) => {
        try {
            e.preventDefault();
            setProgramDeleteConfirmModal(false);
            await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/delete-Program/${programId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            navigate("/Program-list")
        } catch (error) {
            console.log("delete program error",error);
        }
    };

    const createCourseTreeTableData = (courses, courseVideos) => {
        return courses.map((course, index) => {
            const filteredCourseVideos = courseVideos && courseVideos.filter(video =>
                video.course_id === course.content_id);
            const courseNode = {
                key: String(index),
                id: course.content_id,
                hasFeature: false,
                data: {
                    name: course.name.length > 35 ? `${course.name.slice(0, 35)}...` : course.name,
                    actions: {
                        text: <><CiViewList className="fs-4" /> View Course</>, onClick: () =>
                            navigate(`/course-details?course_id=${course.content_id}`)
                    }
                },
                children: [
                    ...(filteredCourseVideos && filteredCourseVideos.length > 0
                        ? filteredCourseVideos.map((video, videoIndex) => ({
                            key: `video-${videoIndex}`,
                            id: video.video_id,
                            hasFeature: true,
                            data: {
                                name: video.video_title.length > 35 ? `${video.video_title.slice(0, 35)}...` : video.video_title,
                                actions: {
                                    text: <><CiViewList className="fs-4" /> View Video</>, onClick: () =>
                                        navigate(`/video-details?video_id=${video.video_id}`)
                                }
                            },
                            children: [],
                        }))
                        : []
                    ),
                ]
            };
            return courseNode;
        });
    };

    const createVideoTreeTableData = (videos) => {
        return videos.map((video, index) => {
            const videoNode = {
                key: String(index),
                id: video.content_id,
                data: {
                    name: video.video_title.length > 35 ? `${video.video_title.slice(0, 35)}...` : video.video_title,
                    actions: {
                        text: <><CiViewList className="fs-4" /> View Video</>, onClick: () =>
                            navigate(`/video-details?video_id=${video.content_id}`)
                    },
                    playVideo: createPlayVideoColumn(video),
                },
                children: []
            };
            return videoNode;
        });
    };

    const renderActions = (rowData) => {
        const { actions } = rowData.data;

        const handleClick = () => {
            actions.onClick();
        };

        return (
            <span onClick={handleClick} style={{ color: "purple", cursor: "pointer" }}>{actions.text}</span>
        );
    };

    const [showPlayVideo, setShowPlayVideo] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);

    const playVideo = (video) => {
        setShowPlayVideo(true);
        setSelectedVideo(video);
    }

    const createPlayVideoColumn = (video) => {
        return [
            {
                label: 'Play Video',
                command: () => playVideo(video)
            }
        ];
    };

    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg': "#0e3f6a"
    }

    const deletebtn = {
        '--cui-btn-bg': "red",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "red",
        '--cui-btn-active-bg': "red",
        "margin-left": "1vw"
    }

    const tableHeader = {
        '--cui-table-bg': "#0e3f6a",
        '--cui-table-color': "white"
    }

    const items = [{ label: `${programDetails && programDetails.name}` }];
    const home = { label: 'Home', url: `${process.env.REACT_APP_WEB_URL}/program-list` }

    return (
        <>
            <div>
                <AppSidebar />
                <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                    <CHeader className="d-flex">
                        <CContainer>
                            <CHeaderToggler
                                className="ps-1"
                                onClick={() =>
                                    dispatch({ type: "set", sidebarShow: !sidebarShow })
                                }
                            >
                                <CIcon icon={cilMenu} size="lg" />
                            </CHeaderToggler>
                            <CHeaderNav className="d-flex me-auto ms-3 mt-2">
                                <h4 className="ms-5">{programDetails && programDetails.name}</h4>
                            </CHeaderNav>
                        </CContainer>
                    </CHeader>
                    {isLoading ?
                        <div style={{
                            marginTop: "10vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <CSpinner />
                        </div> : <div className="mt-5 mx-5">
                            <BreadCrumb model={items} home={home} />
                            <CForm className="row g-3">
                                <CCol md={12}>
                                    <table>
                                        <tbody>
                                            <tr className="table-row">
                                                <td className="program-detail-label">Program Title:</td>
                                                <td className="program-detail-value">{programDetails && programDetails.name}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="program-detail-label">Program Description:</td>
                                                <td className="program-detail-value">{programDetails && programDetails.description}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </CCol>
                            </CForm>

                            {courseTreeTableData && courseTreeTableData.length > 0 && (<div className="mt-5 ">
                                <TreeTable value={courseTreeTableData} header="Courses">
                                    <Column field="name" expander style={{ width: "40%" }}></Column>
                                    <Column field="actions" body={renderActions} style={{ width: "30%" }}></Column>
                                    <Column field="customActions" body={(rowData) => (
                                        <div>{rowData.hasFeature && (
                                            <div><span style={{
                                                color: "green",
                                                cursor: 'pointer'
                                            }} onClick={() => playVideo(rowData)}
                                            ><FaRegCirclePlay className="fs-4" /> Play Video</span>
                                            </div>
                                        )}</div>
                                    )}></Column>
                                </TreeTable>
                            </div>)}

                            {videoTreeTableData && videoTreeTableData.length > 0 && (<div className="mt-5">
                                <TreeTable value={videoTreeTableData} header="Videos">
                                    <Column field="name" expander style={{ width: "40%" }}></Column>
                                    <Column field="actions" body={renderActions} style={{ width: "30%" }}></Column>
                                    <Column field="customActions" body={(rowData) => (
                                        <div><span style={{
                                            color: "green",
                                            cursor: 'pointer'
                                        }} onClick={() => playVideo(rowData)}
                                        ><FaRegCirclePlay className="fs-4" /> Play Video</span>
                                        </div>)}></Column>
                                </TreeTable>
                            </div>)}

                            <div className="d-flex justify-content-end my-3">
                                <CButton
                                    style={btn}
                                    onClick={updateProgram}
                                >
                                    Edit Details
                                </CButton>
                                <CButton
                                    style={deletebtn}
                                    onClick={(e) => handleProgramDeleteConfirmation(e, programDetails.program_id)}>
                                    Delete
                                </CButton>
                            </div>
                        </div>}
                </div>
                {showPlayVideo && (<VideoPlayer
                    show={showPlayVideo}
                    onHide={() => setShowPlayVideo(false)}
                    video={selectedVideo}
                />)}
                <EditProgram
                    show={showEditModal}
                    onHide={() => setShowEditModal(false)}
                    Program={selectedProgram}
                    ProgramCourses={selectedProgramCourse}
                    ProgramVideos={selectedProgramVideo}
                    editProgramFlag={handleEditProgramFlag}
                />
                <ConfirmationModal
                    show={ProgramDeleteConfirmModal}
                    handleClose={() => { setProgramDeleteConfirmModal(false) }}
                    handleConfirm={(e) => deleteProgram(e, selectedProgram)}
                />
            </div>

        </>
    );
};

export default ProgramDetails;
