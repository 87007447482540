import { create } from 'zustand';
import { v4 as uuidv4 } from 'uuid';

const useUploadStore = create((set) => ({
  files: [],

  addFiles: (newFiles) => {
    try {
      if (!Array.isArray(newFiles)) {
        throw new Error("Invalid input: newFiles should be an array.");
      }

      const validFiles = newFiles.filter(({ file, signedUrl }) => {
        if (!file || !signedUrl) {
          console.error("Invalid file object:", { file, signedUrl });
          return false;
        }
        return true;
      });

      if (validFiles.length === 0) {
        throw new Error("No valid files found to add.");
      }

      set((state) => ({
        files: [
          ...state.files,
          ...validFiles.map(({ file, signedUrl }) => ({
            id: uuidv4(),
            file,
            signedUrl
          }))
        ]
      }));
    } catch (error) {
      console.error("Error adding files:", error);
    }
  },

  cleanFiles: () => {
    try {
      set({ files: [] });
    } catch (error) {
      console.error("Error cleaning files:", error);
    }
  }
}));

export default useUploadStore;
