import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BiLogOut } from "react-icons/bi";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarFooter,
  CSidebarNav
} from "@coreui/react";
import { AppSidebarNav } from "./AppSidebarNav";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import navigation from "./Nav";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AppSidebar = () => {
  const visibleFn = useDispatch();
  const [navItems, setNavItems] = useState([navigation[0]]);


  const parseUserFromLocalStorage = () => {
    try {
      const user = localStorage.getItem("user");
      if (!user) return null;
      const parsedUser = JSON.parse(user);
      return parsedUser;
    } catch (error) {
      console.error("Error parsing user from localStorage:", error);
      return null;
    }
  };

  useEffect(() => {
    const user = parseUserFromLocalStorage();
    if (!user) {
      window.location.replace('/login');
      return;
    }
    const userRole = user ? user?.user_role : null;
    if (userRole === 1) {
      setNavItems([navigation[0]]);
    } else {
      setNavItems([navigation[1]]);
    }
  }, []);

  const unfoldable = useSelector((state) => state.sidebarUnfoldable);
  const sidebarShow = useSelector((state) => state.sidebarShow);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const user = parseUserFromLocalStorage();
      if (!user) {
        window.location.replace('/login');
        return;
      }
      const token = user?.token;

      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/logout`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      localStorage.removeItem('user');
      navigate("/login");
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        visibleFn({ type: "set", sidebarShow: visible });
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <h5>RE Content Archive</h5>
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navItems} isOpen={true} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarFooter
        style={{ fontSize: "16px", cursor: "pointer" }}
        onClick={handleLogout}
      >
        <span style={{ marginLeft: "3vw", color: "white" }}>
          <BiLogOut style={{ marginRight: "1vw", color: "white" }} />
          Logout
        </span>
      </CSidebarFooter>
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
