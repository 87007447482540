import React from 'react';

import UserList from './views/pages/user/UserList'; 
import UniversityList from './views/pages/university/UniversityList';
import VideoList from './views/pages/video/VideoList';
import SubscriptionList from './views/pages/subscription/SubscriptionList';
import UniSubsList from './views/pages/uni_subs/UniSubsList';
import CoursesList from './views/pages/course/CourseList';
import ProgramList from './views/pages/program/ProgramList';
import TagList from './views/pages/tag/TagList';

import FacultyList from './views/pages/faculty/FacultyList';
import MySubs1 from './views/pages/mySubs1/mySubs1'
import SubsContent from './views/pages/mySubs1/SubsContent'
import VideoDetails from './views/pages/video/VideoDetails'

import CourseDetails from './views/pages/course/CourseDetails'
import FacultyDetails from './views/pages/faculty/FacultyDetails'
import ProgramDetails from './views/pages/program/ProgramDetails'
import SubscriptionDetails from './views/pages/subscription/SubscriptionDetails'
import UniSubsDetails from './views/pages/uni_subs/UniSubsDetails';


const routes = [
  {
    path: '/user-list',
    name: 'User List',
    element: <UserList />,
    private: true,
  },
  {
    path: '/university-list',
    name: 'University List',
    element: <UniversityList />,
    private: true,
  },
  {
    path: '/video-list',
    name: 'Video List',
    element: <VideoList />,
    private: true,
  },
  {
    path: '/video-details',
    name: 'Video Details',
    element: <VideoDetails />,
    private: true,
  },
  {
    path: '/tag-list',
    name: 'Tag List',
    element: <TagList />,
    private: true,
  },
  {
    path: '/faculty-list',
    name: 'Faculty List',
    element: <FacultyList />,
    private: true,
  },
  {
    path: '/faculty-details',
    name: 'Faculty Details',
    element: <FacultyDetails />,
    private: true,
  },
  {
    path: '/subscription-list',
    name: 'Subscription List',
    element: <SubscriptionList />,
    private: true,
  },
  {
    path: '/subscription-details',
    name: 'Subscription Details',
    element: <SubscriptionDetails />,
    private: true,
  },
  {
    path: '/university-subscription-list',
    name: 'University Subscription List',
    element: <UniSubsList />,
    private: true,
  },
  {
    path: '/uni-subs-details',
    name: 'University Subscription Details',
    element: <UniSubsDetails/>,
    private: true,
  },
  {
    path: '/course-list',
    name: 'Course List',
    element: <CoursesList />,
    private: true,
  },
  {
    path: '/course-details',
    name: 'Course Details',
    element: <CourseDetails />,
    private: true,
  },
  {
    path: '/program-list',
    name: 'Program List',
    element: <ProgramList />,
    private: true,
  },
  {
    path: '/program-details',
    name: 'Program Details',
    element: <ProgramDetails />,
    private: true,
  },
  {
    path: '/my-subscription-list1',
    name: 'My Subs1',
    element: <MySubs1 />,
    private: true,
  },
  {
    path: '/subs-content',
    name: 'Subs Content',
    element: <SubsContent/>,
    private: true,
  },
];

export default routes;
