import React, { useEffect, useState } from "react";
import AppSidebar from "../../../components/AppSidebar";
import {
    CButton,
    CButtonGroup,
    CContainer,
    CHeader,
    CHeaderNav,
    CHeaderToggler,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow, CPagination, CPaginationItem, CSpinner, CCol, CForm, CFormInput
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import AddNewVideo from "./AddNewVideo";
import EditVideo from "./EditVideo";
import { AiTwotoneEdit, AiTwotoneDelete } from "react-icons/ai";
import '../../styling/Pagination.css'
import ConfirmationModal from "../../confirmation/ConfirmationModal";
import { CiViewList } from "react-icons/ci"
import { useNavigate } from "react-router-dom";
import { GoSearch } from "react-icons/go";

const VideoList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const sidebarShow = useSelector((state) => state?.sidebarShow);

    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showVideoDeleteConfirmModal, setShowVideoDeleteConfirmModal] = useState(false);

    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedVideoTags, setSelectedVideoTags] = useState(null);
    const [selectedVideoCourses, setSelectedVideoCourses] = useState(null);
    const [selectedVideoFaculty, setSelectedVideoFaculty] = useState(null);

    const [videos, setVideos] = useState([]);
    const [tags, setTags] = useState([]);

    const [courses, setCourses] = useState([]);
    const [faculty, setFaculty] = useState([]);
    const [flag, setFlag] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const parseUserFromLocalStorage = () => {
        try {
            const user = localStorage.getItem("user");
            if (!user) {
                window.location.replace('/login');
                return;
            }
            const parsedUser = JSON.parse(user);
            return parsedUser;
        } catch (error) {
            console.error("Error parsing user from localStorage:", error);
            return null;
        }
    };
    const user = parseUserFromLocalStorage();
    const { token } = user;

    useEffect(() => {
        const getVideos = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-videos`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setVideos(response.data.data);
                setCourses(response.data.videoCourseData);
                setFaculty(response.data.videoFacultyData);
                setTags(response.data.videoTagsData);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching videos:", error);
            }
        };
        if (user) {
            getVideos();
        }
        setFlag(false);
    }, [flag]);


    const videoTypeMapping = {
        1: "H5P",
        2: "HTML5"
    }

    const videoStatusMapping = {
        0: "Processing",
        1: "Ready to Use"
    }

    const handleAddVideoFlag = (addVideoFlag) => {
        setFlag(addVideoFlag);
    }

    const handleEditVideoFlag = (editVideoFlag) => {
        setFlag(editVideoFlag);
    }

    const coursesToBeUpdated = [];
    const facultyToBeUpdated = [];
    const tagsToBeUpdated = [];
    const updateVideo = async (e, video) => {
        courses.map((course) => (
            video.video_id === course.video_id ?
                coursesToBeUpdated.push(course) : <></>
        ))

        faculty.map((faculty) => (
            video.video_id === faculty.video_id ?
                facultyToBeUpdated.push(faculty) : <></>
        ))

        tags.map((tag) => (
            video.video_id === tag.video_id ?
                tagsToBeUpdated.push(tag) : <></>
        ))

        setShowEditModal(true);
        setSelectedVideo(video);
        setSelectedVideoCourses(coursesToBeUpdated);
        setSelectedVideoFaculty(facultyToBeUpdated);
        setSelectedVideoTags(tagsToBeUpdated);
    }

    const handleVideoDeleteConfirmation = (e, video) => {
        setShowVideoDeleteConfirmModal(true);
        setSelectedVideo(video);
    }

    const deleteVideo = async (e, video) => {
        try {
            e.preventDefault();
            setShowVideoDeleteConfirmModal(false);
            const video_id = video.video_id;
            await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/delete-video/${video_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }, data: {
                    videoURL: video.video_url
                }
            });
            setFlag(true);
        } catch (error) {
            console.log(error);
        }
    };

    const [stateSearch, setStateSearch] = useState(false);
    const [searchError, setSearchError] = useState("");
    const [searchData, setSearchData] = useState([]);

    const handleInputChange = (e) => {
        setStateSearch(true);
        setSearchQuery(e.target.value);
        if (e.target.value.trim() === '') {
            setSearchData(displayVideos);
            setSearchError("");
        } else {
            filterData(e.target.value);
        }
    };

    const filterData = (query) => {
        const videoTypeMap = {
            "h5p": 1,
            "html5": 2,
        };

        const queryKey = query.toLowerCase();
        const partialMatches = Object.keys(videoTypeMap).filter(key =>
            key.toLowerCase().includes(queryKey)
        );

        const partialMatchValues = partialMatches.map(key => videoTypeMap[key]);

        const filteredData = displayVideos.filter(video => {
            const filteredFaculty = faculty.filter(faculty => faculty.video_id === video.video_id);

            const facultyNames = filteredFaculty.map(faculty => `${faculty.first_name} ${faculty.last_name}`.toLowerCase());

            return (
                video.video_title.toLowerCase().includes(query.toLowerCase()) ||
                partialMatchValues.includes(video.video_type) ||
                facultyNames.some(name => name.includes(query.toLowerCase()))
            );
        });

        if (filteredData.length === 0) {
            setSearchError("No data found");
        } else {
            setSearchData(filteredData);
            setSearchError("");
        }
    };

    const [pageNumber, setPageNumber] = useState(0);
    const itemsPerPage = 25;
    const pageVisited = pageNumber * itemsPerPage;
    const displayVideos = videos.slice(pageVisited, pageVisited + itemsPerPage);
    const pageCount = Math.ceil(videos.length / itemsPerPage);

    const tableHeader = {
        '--cui-table-bg': "#0e3f6a",
        '--cui-table-color': "white"
    }

    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg': "#0e3f6a",
        '--cui-btn-padding-y': "5px"
    }

    return (
        <div>
            <AppSidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <CHeader className="d-flex">
                    <CContainer>
                        <CHeaderToggler
                            className="ps-1"
                            onClick={() =>
                                dispatch({ type: "set", sidebarShow: !sidebarShow })
                            }
                        >
                            <CIcon icon={cilMenu} size="lg" />
                        </CHeaderToggler>
                        <CHeaderNav className="d-flex me-auto ms-3 mt-2">
                            <h4 style={{ marginRight: '3vw' }}>Videos List</h4>
                            <CCol sm={4}>
                                <CForm className="d-flex">
                                    <CFormInput
                                        type="text"
                                        placeholder="Search"
                                        className="me-2 rounded-pill"
                                        style={{ width: "20vw" }}
                                        aria-label="Search"
                                        onChange={handleInputChange}
                                        value={searchQuery}
                                    />
                                    <span style={{
                                        marginLeft: "-3vw",
                                        marginTop: "1vh"
                                    }}><GoSearch /> </span>
                                </CForm>
                            </CCol>
                        </CHeaderNav>

                        <CHeaderNav>
                            <CButtonGroup>
                                <CButton
                                    style={btn} variant="outline"
                                    onClick={() => setShowAddModal(true)}
                                >
                                    Add Video
                                </CButton>
                            </CButtonGroup>
                        </CHeaderNav>
                    </CContainer>
                </CHeader>
                <div
                    className="ms-4 mt-5 me-4">
                    <CTable
                        striped>
                        <CTableHead style={tableHeader}>
                            <CTableRow>
                                <CTableHeaderCell style={{ width: "30%", paddingLeft: "3vw" }}>
                                    Title
                                </CTableHeaderCell>
                                <CTableHeaderCell style={{ width: "15%" }}>
                                    Video Type
                                </CTableHeaderCell>
                                <CTableHeaderCell style={{ width: "20%" }}>
                                    Video Faculty
                                </CTableHeaderCell>
                                <CTableHeaderCell style={{ width: "20%" }}>
                                    Video Status
                                </CTableHeaderCell>
                                <CTableHeaderCell>
                                    Actions
                                </CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody style={{ padding: "5px" }}>
                            {stateSearch ? (searchError !== "" ?
                                (<CTableRow className="text-center">
                                    <CTableDataCell colSpan={6}>{searchError}</CTableDataCell>
                                </CTableRow>) :
                                searchData && searchData.map((video, index) => {
                                    return (
                                        <CTableRow
                                            v-for="item in tableItems"
                                            key={index}>
                                            <CTableDataCell style={{ paddingLeft: "3vw" }}>
                                                <span>{video.video_title}</span>
                                            </CTableDataCell>
                                            <CTableDataCell>
                                                <span >{videoTypeMapping[video.video_type]}</span>
                                            </CTableDataCell>
                                            <CTableDataCell style={{ width: "15vw" }}>
                                                {faculty.map((faculty, index) => (
                                                    video.video_id === faculty.video_id ? (
                                                        <div key={index}>
                                                            <span>{faculty.first_name} {faculty.last_name}</span>
                                                            <br /></div>) : null
                                                ))}
                                            </CTableDataCell>
                                            <CTableDataCell>
                                                <span>{videoStatusMapping[video.status]}</span>
                                            </CTableDataCell>
                                            <CTableDataCell>
                                                <div className="d-flex mt-2">
                                                    <div
                                                        style={{ color: "blue", marginRight: "1vw", cursor: "pointer" }}
                                                        onClick={(e) => navigate(`/video-details?video_id=${video.video_id}`)}
                                                    >
                                                        <CiViewList />
                                                    </div>
                                                    <div
                                                        style={{ color: "darkgreen", marginRight: "1vw", cursor: "pointer" }}
                                                        onClick={(e) => updateVideo(e, video)}
                                                    >
                                                        <AiTwotoneEdit />
                                                    </div>
                                                    <span
                                                        style={{ color: "red", marginRight: "1vw", cursor: "pointer" }}
                                                        onClick={(e) => handleVideoDeleteConfirmation(e, video)}
                                                    >
                                                        <AiTwotoneDelete />
                                                    </span>
                                                </div>
                                            </CTableDataCell>
                                        </CTableRow>
                                    )
                                })) : isLoading ? <CTableRow>
                                    <CTableDataCell className="text-center" colSpan="6">
                                        <CSpinner />
                                    </CTableDataCell>
                                </CTableRow> : displayVideos.length === 0 ? (
                                    <CTableRow>
                                        <CTableDataCell className="text-center" colSpan="7">
                                            <span>No data to show</span>
                                        </CTableDataCell>
                                    </CTableRow>
                                ) : displayVideos.map((video, index) => {
                                    return (
                                        <CTableRow
                                            v-for="item in tableItems"
                                            key={index}>
                                            <CTableDataCell style={{ paddingLeft: "3vw" }}>
                                                <span>{video.video_title}</span>
                                            </CTableDataCell>
                                            <CTableDataCell>
                                                <span >{videoTypeMapping[video.video_type]}</span>
                                            </CTableDataCell>
                                            <CTableDataCell style={{ width: "15vw" }}>
                                                {faculty.map((faculty, index) => (
                                                    video.video_id === faculty.video_id ? (
                                                        <div key={index}>
                                                            <span>{faculty.first_name} {faculty.last_name}</span>
                                                            <br /></div>) : null
                                                ))}
                                            </CTableDataCell>
                                            <CTableDataCell>
                                                <span>{videoStatusMapping[video.status]}</span>
                                            </CTableDataCell>
                                            <CTableDataCell>
                                                <div className="d-flex mt-2">
                                                    <div
                                                        style={{ color: "blue", marginRight: "1vw", cursor: "pointer" }}
                                                        onClick={(e) => navigate(`/video-details?video_id=${video.video_id}`)}
                                                    >
                                                        <CiViewList />
                                                    </div>
                                                    <div
                                                        style={{ color: "darkgreen", marginRight: "1vw", cursor: "pointer" }}
                                                        onClick={(e) => updateVideo(e, video)}
                                                    >
                                                        <AiTwotoneEdit />
                                                    </div>
                                                    <span
                                                        style={{ color: "red", marginRight: "1vw", cursor: "pointer" }}
                                                        onClick={(e) => handleVideoDeleteConfirmation(e, video)}
                                                    >
                                                        <AiTwotoneDelete />
                                                    </span>
                                                </div>
                                            </CTableDataCell>
                                        </CTableRow>
                                    )
                                })
                            }
                        </CTableBody>
                    </CTable>
                </div>
                {displayVideos.length > 0 && !searchError &&
                    (<CPagination align="center" aria-label="Page navigation example" className="cursor-pointer">
                        <CPaginationItem
                            aria-label="Previous"
                            onClick={() => setPageNumber(pageNumber - 1)}
                            disabled={pageNumber === 0}
                        >
                            <span aria-hidden="true">&laquo;</span>
                        </CPaginationItem>

                        {Array.from({ length: pageCount }, (_, index) => (
                            <CPaginationItem
                                key={index + 1}
                                active={pageNumber === index}
                                onClick={() => setPageNumber(index)}
                            >
                                {index + 1}
                            </CPaginationItem>
                        ))}

                        <CPaginationItem
                            aria-label="Next"
                            onClick={() => setPageNumber(pageNumber + 1)}
                            disabled={pageNumber === pageCount - 1}
                        >
                            <span aria-hidden="true">&raquo;</span>
                        </CPaginationItem>
                    </CPagination>)}
            </div>

            <AddNewVideo
                show={showAddModal}
                onHide={() => setShowAddModal(false)}
                addVideoFlag={handleAddVideoFlag} />
            <EditVideo
                show={showEditModal}
                onHide={() => setShowEditModal(false)}
                video={selectedVideo}
                videoCourses={selectedVideoCourses}
                videoFaculty={selectedVideoFaculty}
                videoTags={selectedVideoTags}
                editVideoFlag={handleEditVideoFlag}
            />
            <ConfirmationModal
                show={showVideoDeleteConfirmModal}
                handleClose={() => { setShowVideoDeleteConfirmModal(false) }}
                handleConfirm={(e) => deleteVideo(e, selectedVideo)}
            />
        </div>
    );
};

export default VideoList;
