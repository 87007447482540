import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import {
    CForm,
    CCol,
    CFormInput,
    CFormSelect,
    CButton,
    CFormFeedback,
    CFormTextarea
} from "@coreui/react";
import axios from "axios";
import { CiCircleRemove } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

const EditProgram = (props) => {
    const navigate = useNavigate();
    const handleShow = () => {
        setErrors({});
    };

    const [coursesField, setCoursesField] = useState(['']);
    const [courseList, setCourseList] = useState([]);
    const [videosField, setVideosField] = useState(['']);
    const [videoList, setVideoList] = useState([]);
    const [errors, setErrors] = useState({});

    const ProgramData = props?.Program;
    const coursesData = props?.ProgramCourses;
    const videosData = props?.ProgramVideos;

    const initialFormData = {
        name: ProgramData ? ProgramData.name : "",
        description: ProgramData ? ProgramData.description : "",
        addCourses: [],
        deleteCourses: [],
        addVideos: [],
        deleteVideos: []
    };

    const [formData, setFormData] = useState(initialFormData);
    useEffect(() => {
        setFormData(initialFormData);
    }, [ProgramData])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
        setErrors((prev) => ({ ...prev, [name]: "" }));
    };

    const parseUserFromLocalStorage = () => {
        try {
            const user = localStorage.getItem("user");
            if (!user) {
                window.location.replace('/login');
                return;
            }
            const parsedUser = JSON.parse(user);
            return parsedUser;
        } catch (error) {
            console.error("Error parsing user from localStorage:", error);
            return null;
        }
    };

    const user = parseUserFromLocalStorage();
    const { token } = user;

    useEffect(() => {
        const getCourseList = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-courses`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setCourseList(response?.data?.data);

            } catch (error) {
                console.error("Error fetching courses:", error);
            }
        };

        const getVideoList = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-videos`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setVideoList(response?.data?.data);

            } catch (error) {
                console.error("Error fetching videos:", error);
            }
        };
        if (user) {
            getCourseList();
            getVideoList();
        }
    }, []);

    useEffect(() => {
        if (coursesData && coursesData.length > 0) {
            const ProgramCoursesData = coursesData.map(course => ({
                courseId: course?.content_id,
                courseName: course?.name,
                ProgramId: course?.program_id
            }));
            setCoursesField(ProgramCoursesData);
        } else {
            setCoursesField([]);
        }
    }, [coursesData]);

    useEffect(() => {
        if (videosData && videosData.length > 0) {
            const ProgramVideosData = videosData.map(video => ({
                videoId: video?.content_id,
                videoName: video?.video_title,
                ProgramId: video?.program_id
            }));
            setVideosField(ProgramVideosData);
        } else {
            setVideosField([]);
        }
    }, [videosData]);

    const [selectedCourseId, setSelectedCourseId] = useState(null);
    const handleCourseOptions = (course_id) => {
        setSelectedCourseId(course_id);
    }

    const handleCourseChange = (index, e) => {
        const selectedCourseId = parseInt(e.target.value, 10);
        const selectedCourse = courseList.find(course => course.course_id === selectedCourseId);

        setFormData((prev) => {
            const newAddCourses = [...(prev.addCourses || [])];

            if (selectedCourse && selectedCourse.course_id) {
                newAddCourses[index] = selectedCourse.course_id;
                if (coursesField[index] && coursesField[index].courseId) {
                    const newDeleteCourses = [...(prev.deleteCourses || []), coursesField[index].courseId];
                    return {
                        ...prev,
                        addCourses: newAddCourses.filter(Boolean),
                        deleteCourses: newDeleteCourses
                    };
                }
            } else {
                console.error("Selected course is undefined or missing course_id property for addCourse");
            }

            return {
                ...prev,
                addCourses: newAddCourses
            };
        });
        setErrors((prev) => ({ ...prev, addCourses: "" }));
    };

    const addCourse = () => {
        const newCoursesData = [...coursesField, 'newCourse'];
        setCoursesField(newCoursesData);
    };

    const removeCourse = (index) => {
        const newCoursesData = [...coursesField];

        setFormData((prev) => {
            let courseIdTobeDeleted;
            if (coursesField[index].courseId) {
                courseIdTobeDeleted = coursesField[index].courseId;
            } else {
                courseIdTobeDeleted = formData.addCourses[index];
            }
            const newDeleteCourses = [...(prev.deleteCourses || []), courseIdTobeDeleted];
            const newAddCourses = [...(prev.addCourses || [])];
            newAddCourses.splice(index, 1);
            return {
                ...prev,
                deleteCourses: newDeleteCourses,
                addCourses: newAddCourses.filter(Boolean)
            };
        });

        newCoursesData.splice(index, 1);
        setCoursesField(newCoursesData);
    };

    const [selectedVideoId, setSelectedVideoId] = useState(null);
    const handleOptions = (video_id) => {
        setSelectedVideoId(video_id);
    }

    const handleVideoChange = (index, e) => {
        const selectedVideoId = parseInt(e.target.value, 10);
        const selectedVideo = videoList.find(video => video.video_id === selectedVideoId);

        setFormData((prev) => {
            const newAddVideos = [...(prev.addVideos || [])];

            if (selectedVideo && selectedVideo.video_id) {
                newAddVideos[index] = selectedVideo.video_id;

                if (videosField[index] && videosField[index].videoId) {
                    const newDeleteVideos = [...(prev.deleteVideos || []), videosField[index].videoId];
                    return {
                        ...prev,
                        addVideos: newAddVideos.filter(Boolean),
                        deleteVideos: newDeleteVideos
                    };
                }
            } else {
                console.error("Selected video is undefined or missing video_id property for addVideos");
            }

            return {
                ...prev,
                addVideos: newAddVideos.filter(Boolean),
            };
        });

        setErrors((prev) => ({ ...prev, addVideos: "" }));
    };

    const addVideo = () => {
        const newVideosData = [...videosField, "newVideo"];
        setVideosField(newVideosData);
    };

    const removeVideo = (index) => {
        const newVideosData = [...videosField];

        setFormData((prev) => {
            let videoIdTobeDeleted;
            if (videosField[index].videoId) {
                videoIdTobeDeleted = videosField[index].videoId;
            } else {
                videoIdTobeDeleted = formData.addVideos[index]
            }
            const newDeleteVideos = [...(prev.deleteVideos || []), videoIdTobeDeleted];
            const newAddVideos = [...(prev.addVideos || [])];
            newAddVideos.splice(index, 1);
            return {
                ...prev,
                deleteVideos: newDeleteVideos,
                addVideos: newAddVideos.filter(Boolean)
            };
        });

        newVideosData.splice(index, 1);
        setVideosField(newVideosData);
    };

    const [flag, setFlag] = useState(false);
    const handleEditProgramFlag = (flag) => {
        props.editProgramFlag(flag);
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            await axios.put
                (`${process.env.REACT_APP_API_BASE_URL}/update-Program/${ProgramData.program_id}`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            handleEditProgramFlag(setFlag(true));
            props.onHide();

        } catch (err) {
            console.log("error in editing Program", err);
            if (err.response.data.errors) {
                const apiErrors = err.response.data.errors;
                const fieldErrors = {};
                Object.keys(apiErrors).forEach((field) => {
                    fieldErrors[field] = apiErrors[field].join(" ");
                });
                setErrors(fieldErrors);
                console.log(fieldErrors)
            }
        }
    };

    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg': "#0e3f6a"
    }

    return (
        <Modal
            {...props}
            size="lg"
            onShow={handleShow}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header style={{
                backgroundColor: "#0e3f6a",
                color: "white"
            }}
                closeButton closeVariant="white">
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit Program
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CForm className="row g-3">
                    <CCol md={12}>
                        <CFormInput
                            type="text"
                            label="Program Name"
                            name="name"
                            placeholder="Enter a program name"
                            onChange={handleChange}
                            value={formData.name}
                            isInvalid={!!errors.name}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.name}</CFormFeedback>
                    </CCol>
                    <CCol md={12}>
                        <CFormTextarea
                            rows={3}
                            label="Description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            isInvalid={!!errors.description}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.description}</CFormFeedback>
                    </CCol>
                    {/* courses */}
                    <div>
                        <h6 style={{ fontWeight: "normal" }}>Courses</h6>
                        <div style={{ marginBottom: "10px" }}>
                            <CButton
                                onClick={addCourse}
                                style={btn}
                            >Add</CButton>

                        </div>
                        <div >
                            {coursesField && coursesField.map((course, index) => (
                                <div key={index}
                                    style={{
                                        display: "flex",
                                        marginRight: "2vw",
                                        marginBottom: "10px"
                                    }}>
                                    <CCol md={12} style={{
                                        display: "flex"
                                    }}>
                                        <CFormSelect
                                            size="md"
                                            className="mb-3"
                                            aria-label="Small select example"
                                            placeholder="Choose a course in Program"
                                            onChange={(e) => handleCourseChange(index, e)}
                                            required
                                        >
                                            <option value={course.courseId}
                                                onClick={() => handleCourseOptions(course.courseId)}>
                                                {course.courseName}
                                            </option>
                                            {courseList && courseList.map((options) => (
                                                course.courseId !== options.course_id ?
                                                    <option key={options.course_id}
                                                        value={options.course_id}
                                                        onClick={() => handleCourseOptions(options.course_id)}>
                                                        {options.name}
                                                    </option> : <></>
                                            ))}
                                        </CFormSelect>
                                        <span
                                            onClick={() => removeCourse(index)}
                                            style={{
                                                color: "red",
                                                fontSize: "30px",
                                                backgroundColor: "transparent",
                                                cursor: "pointer",
                                                marginTop: "-8px"
                                            }}><CiCircleRemove /></span>
                                    </CCol>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* videos */}
                    <div>
                        <h6 style={{ fontWeight: "normal" }}>Videos</h6>
                        <div style={{ marginBottom: "10px" }}>
                            <CButton
                                onClick={addVideo}
                                style={btn}
                            >Add</CButton>

                        </div>
                        <div>
                            {videosField && videosField.map((video, index) => (
                                <div key={index}
                                    style={{
                                        display: "flex",
                                        marginRight: "2vw",
                                        marginBottom: "10px"
                                    }}>
                                    <CCol md={12} style={{
                                        display: "flex"
                                    }}>
                                        <CFormSelect
                                            size="md"
                                            className="mb-3"
                                            aria-label="Small select example"
                                            placeholder="Choose a video in Program"
                                            // value={tag.tagName}
                                            onChange={(e) => handleVideoChange(index, e)}
                                            required
                                        >
                                            <option value={video.videoId}
                                                onClick={() => handleOptions(video.videoId)}>
                                                {video.videoName}</option>
                                            {videoList && videoList.map((options) => (
                                                video.videoId !== options.video_id ?
                                                    <option key={options.video_id} value={options.video_id}
                                                        onClick={() => handleOptions(options.video_id)}>
                                                        {options.video_title}
                                                    </option> : <></>
                                            ))}
                                        </CFormSelect>
                                        <span
                                            onClick={() => removeVideo(index)}
                                            style={{
                                                color: "red",
                                                fontSize: "30px",
                                                backgroundColor: "transparent",
                                                cursor: "pointer",
                                                marginTop: "-8px"
                                            }}><CiCircleRemove /></span>
                                    </CCol>
                                </div>
                            ))}
                        </div>
                        {errors.general ? <CFormFeedback style={{ color: "red" }}>
                            {errors.general}</CFormFeedback> : <></>}
                    </div>
                </CForm>
            </Modal.Body>
            <Modal.Footer className="mt-3">
                <CButton style={btn} type="submit" onClick={handleSubmit}>
                    Submit
                </CButton>
                <CButton onClick={props.onHide} color="secondary">Cancel</CButton>
            </Modal.Footer>
        </Modal>
    );
};

export default EditProgram;
