import React, { useState } from 'react';

const HoverPopup = ({ description, children }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {children}
            {isHovered && <div style={{
                width: "20vw",
                position: "absolute",
                backgroundColor: "#f9f9f9",
                border: "1px solid #ddd",
                padding: "10px",
                zIndex: "1"
            }}>
                {description.split(" ").slice(0, 10).join(" ")}{description.split(" ").length > 10 ? "..." : ""}
            </div>}
        </div>
    );
};

export default HoverPopup;
