import { useState, useEffect } from "react";
import {
    CForm,
    CCol,
    CFormInput,
    CHeader,
    CHeaderNav,
    CHeaderToggler,
    CContainer,
    CFormTextarea,
    CSpinner,
    CButton,
    CTable, CTableHead, CTableHeaderCell, CTableRow, CTableDataCell, CTableBody
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AppSidebar from "../../../components/AppSidebar";
import ConfirmationModal from "../../confirmation/ConfirmationModal";
import EditCourse from "./EditCourse";
import { OrderList } from 'primereact/orderlist';
import './Course.css';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { BreadCrumb } from 'primereact/breadcrumb';

const CourseDetails = () => {
    const dispatch = useDispatch();
    const sidebarShow = useSelector((state) => state?.sidebarShow);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('course_id');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const [courseDetails, setCourseDetails] = useState([]);
    const [courseVideos, setCourseVideos] = useState([]);
    const [courseFaculty, setCourseFaculty] = useState([]);
    const [courseVideoFaculty, setCourseVideoFaculty] = useState([]);
    const [flag, setFlag] = useState(false);

    const parseUserFromLocalStorage = () => {
        try {
            const user = localStorage.getItem("user");
            if (!user) {
                window.location.replace('/login');
                return;
            }
            const parsedUser = JSON.parse(user);
            return parsedUser;
        } catch (error) {
            console.error("Error parsing user from localStorage:", error);
            return null;
        }
    };
    const user = parseUserFromLocalStorage();
    const { token } = user;

    useEffect(() => {
        const [navItem] = document.querySelectorAll("[href='/course-list']");
        navItem.classList.add("active");

        const navGroup = document.querySelector(".nav-group");
        navGroup.classList.add("show");

        const getCourseDetails = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-courses/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setCourseDetails(response?.data?.data);
                setCourseVideos(response?.data?.courseVideoData?.data);
                setCourseFaculty(response?.data?.courseFacultyData);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching Course-details:", error);
            }
        };

        const getCourseVideosFaculty = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-video-faculty`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setCourseVideoFaculty(response?.data?.data)
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching video-faculty:", error);
            }
        };
        if (user) {
            getCourseDetails();
            getCourseVideosFaculty();
        }
        setFlag(false);
    }, [flag]);

    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedCourseFaculty, setSelectedCourseFaculty] = useState(null);

    const updateCourse = async () => {
        setShowEditModal(true);
        setSelectedCourse(courseDetails);
        setSelectedCourseFaculty(courseFaculty);
    }

    const handleEditCourseFlag = (editCourseFlag) => {
        setFlag(editCourseFlag);
    }

    const [CourseDeleteConfirmModal, setCourseDeleteConfirmModal] = useState(false);

    const handleCourseDeleteConfirmation = (e, courseId) => {
        setCourseDeleteConfirmModal(true);
        setSelectedCourse(courseId);
    }

    const deleteCourse = async (e, courseId) => {
        try {
            e.preventDefault();
            setCourseDeleteConfirmModal(false);
            await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/delete-Course/${courseId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            navigate("/Course-list")
        } catch (error) {
            console.error("delete-course error", error);
        }
    };

    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg': "#0e3f6a"
    }

    const deletebtn = {
        '--cui-btn-bg': "red",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "red",
        '--cui-btn-active-bg': "red",
        "margin-left": "1vw"
    }

    const tableHeader = {
        '--cui-table-bg': "#0e3f6a",
        '--cui-table-color': "white",
        "marginLeft": "5vw",
        "marginRight": "5vw",
        "fontWeight": "normal"
    }

    const [videos, setVideos] = useState([]);
    const [prevVideos, setPrevVideos] = useState([]);

    useEffect(() => {
        setVideos(courseVideos);
        setPrevVideos(courseVideos);
    }, [courseVideos]);

    useEffect(() => {
        if (prevVideos && prevVideos.length !== 0 && videos &&
            videos.length !== 0 && prevVideos !== videos) {
            updateSortOrderOfVideos();
        }
    }, [videos]);

    const onReorder = (e) => {
        const newVideos = e.value;
        setPrevVideos(videos);
        setVideos(newVideos);
    };

    const updateSortOrderOfVideos = async () => {
        try {
            const updatedVideos = videos.map((video, index) => ({
                video_courses_mapping_id: video.video_courses_mapping_id,
                sort_order: index + 1
            }));

            await axios.put(`${process.env.REACT_APP_API_BASE_URL}/update-sort-order`, updatedVideos, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        } catch (error) {
            console.error('Error updating sort order', error);
        }
    };

    const itemTemplate = (video) => {
        return (
            <CTableBody>
                <CTableRow style={{ border: "none" }}>
                    <CTableDataCell style={{ width: "20vw" }}>
                        {video.video_title.length > 27 ? `${video.video_title.slice(0, 27)}...` : video.video_title}
                    </CTableDataCell>
                    <CTableDataCell style={{ width: "15vw", paddingLeft: "1vw" }}>
                        {courseVideoFaculty.map((faculty, index) => (
                            video.video_id === faculty.video_id ? (
                                <div key={index}>
                                    <span>{faculty.first_name} {faculty.last_name}</span>
                                    <br /></div>) : null
                        ))}
                    </CTableDataCell>
                    <CTableDataCell>
                        <span style={{ width: "10vw", color: "blue", cursor: "pointer", paddingLeft: "1vw" }}
                            onClick={(e) => navigate(`/video-details?video_id=${video.video_id}`)}>
                            View </span>
                    </CTableDataCell>
                </CTableRow>
            </CTableBody>
        );
    };

    const items = [{ label: `${courseDetails.name}` }];
    const home = { label: 'Home', url: `${process.env.REACT_APP_WEB_URL}/course-list` }

    return (
        <>
            <div>
                <AppSidebar />
                <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                    <CHeader className="d-flex">
                        <CContainer>
                            <CHeaderToggler
                                className="ps-1"
                                onClick={() =>
                                    dispatch({ type: "set", sidebarShow: !sidebarShow })
                                }
                            >
                                <CIcon icon={cilMenu} size="lg" />
                            </CHeaderToggler>
                            <CHeaderNav className="d-flex me-auto ms-3 mt-2">
                                <h4 className="ms-5">{courseDetails && courseDetails.name}</h4>
                            </CHeaderNav>
                        </CContainer>
                    </CHeader>
                    {isLoading ?
                        <div style={{
                            marginTop: "10vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <CSpinner />
                        </div> : <div className="mt-5 mx-5">
                            <BreadCrumb model={items} home={home} />
                            <CForm className="row g-3">
                                <CCol md={12}>
                                    <table>
                                        <tbody>
                                            <tr className="table-row">
                                                <td className="course-detail-label">Course Title:</td>
                                                <td className="course-detail-value">{courseDetails && courseDetails.name}</td>
                                            </tr>
                                            <tr className="table-row">
                                                <td className="course-detail-label">Course Description:</td>
                                                <td className="course-detail-value">{courseDetails && courseDetails.description}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </CCol>

                                {courseVideos && courseVideos.length > 0 && (
                                    <div className="mt-2">
                                        <span style={{ fontWeight: "bold" }}>Course Videos:</span>
                                        <CTable striped style={{ width: '45vw', marginTop: "1vw" }}>
                                            <CTableHead style={tableHeader}>
                                                <CTableRow style={{ border: 0 }}>
                                                    <div style={{
                                                        width: "45vw",
                                                        marginLeft: "4.7rem",
                                                        marginRight: "2rem",
                                                        display: "flex"
                                                    }}>
                                                        <CTableHeaderCell style={{ width: "20vw", paddingLeft: "2vw" }}>
                                                            Video Title
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell style={{ width: "15vw", paddingLeft: "3vw" }}>
                                                            Video Faculty
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell style={{ width: "10vw", paddingLeft: "3vw" }}>
                                                            Actions
                                                        </CTableHeaderCell>
                                                    </div>
                                                </CTableRow>
                                            </CTableHead>
                                            <OrderList
                                                value={videos}
                                                onChange={onReorder}
                                                itemTemplate={itemTemplate}
                                                controlsPosition="right"
                                                dragdrop={true}
                                            />
                                        </CTable>
                                    </div>
                                )}

                                {courseFaculty && courseFaculty.length > 0 && (<div>
                                    <span style={{ fontWeight: "bold" }}>Course Faculty:</span>
                                    <CTable striped style={{ width: '46vw', marginTop: "1vw" }}>
                                        <CTableHead style={tableHeader}>
                                            <CTableRow>
                                                <CTableHeaderCell style={{ width: '30%', paddingLeft: "3vw" }}>
                                                    Credential
                                                </CTableHeaderCell>
                                                <CTableHeaderCell style={{ width: '40%' }}>
                                                    Faculty Name
                                                </CTableHeaderCell>
                                                <CTableHeaderCell className="text-center">
                                                    Actions
                                                </CTableHeaderCell>
                                            </CTableRow>
                                        </CTableHead>
                                        <CTableBody style={{ padding: "5px" }}>
                                            {courseFaculty && courseFaculty.map((faculty, index) => (
                                                <CTableRow
                                                    v-for="item in tableItems"
                                                    key={index} >
                                                    <CTableDataCell style={{ paddingLeft: "3vw" }}>
                                                        <span>{faculty.credential}</span>
                                                    </CTableDataCell>
                                                    <CTableDataCell>
                                                        <div>
                                                            <span>{`${faculty.first_name} ${faculty.last_name}`}</span>
                                                        </div>
                                                    </CTableDataCell>
                                                    <CTableDataCell className="text-center">
                                                        <div style={{ color: "blue", marginRight: "1vw", cursor: "pointer" }}
                                                            onClick={(e) => navigate(`/faculty-details?faculty_id=${faculty.faculty_id}`)}>
                                                            View
                                                        </div>
                                                    </CTableDataCell>
                                                </CTableRow>
                                            ))
                                            }
                                        </CTableBody>
                                    </CTable>
                                </div>
                                )}

                            </CForm>
                            <div className="d-flex justify-content-end my-3">
                                <CButton
                                    style={btn}
                                    onClick={updateCourse}
                                >
                                    Edit Details
                                </CButton>
                                <CButton
                                    style={deletebtn}
                                    onClick={(e) => handleCourseDeleteConfirmation(e, courseDetails.course_id)}>
                                    Delete
                                </CButton>
                            </div>
                        </div>}
                </div>
                <EditCourse
                    show={showEditModal}
                    onHide={() => setShowEditModal(false)}
                    course={selectedCourse}
                    courseFaculty={selectedCourseFaculty}
                    editCourseFlag={handleEditCourseFlag}
                />
                <ConfirmationModal
                    show={CourseDeleteConfirmModal}
                    handleClose={() => { setCourseDeleteConfirmModal(false) }}
                    handleConfirm={(e) => deleteCourse(e, selectedCourse)}
                />
            </div>

        </>
    );
};

export default CourseDetails;
