import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import {
  CForm,
  CCol,
  CFormInput,
  CFormSelect,
  CButton,
  CFormFeedback,
} from "@coreui/react";
import axios from "axios";

const AddFaculty = (props) => {
  const handleShow = () => {
    setFormData({
      credential: "",
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
    });
    setFlag(false);
    setErrors({});
    setSubmitted(false);
  };

  const [formData, setFormData] = useState({
    credential: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
  });

  const [errors, setErrors] = useState({});
  const [countries, setCountries] = useState([]);

  const parseUserFromLocalStorage = () => {
    try {
      const user = localStorage.getItem("user");
      if (!user) {
        window.location.replace('/login');
        return;
      }
      const parsedUser = JSON.parse(user);
      return parsedUser;
    } catch (error) {
      console.error("Error parsing user from localStorage:", error);
      return null;
    }
  };

  const user = parseUserFromLocalStorage();
  const { token } = user;

  useEffect(() => {
    const getCountries = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-countries`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCountries(response?.data?.data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    }

    if (user) {
      getCountries();
    }
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const [flag, setFlag] = useState(false);

  const handleAddFacultyFlag = (flag) => {
    flag = true;
    props.addFacultyFlag(flag);
  }

  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setSubmitted(true);
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/add-faculty`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      handleAddFacultyFlag(setFlag(true));
      props.onHide();
    } catch (err) {
      console.log("error in adding Faculty", err);
      if (err.response.data.errors) {
        const apiErrors = err.response.data.errors;
        const fieldErrors = {};
        Object.keys(apiErrors).forEach((field) => {
          fieldErrors[field] = apiErrors[field].join(" ");
        });
        setErrors(fieldErrors);
      }
    } finally {
      setSubmitted(false);
    }
  };

  const btn = {
    '--cui-btn-bg': "#0e3f6a",
    '--cui-btn-color': "white",
    '--cui-btn-hover-bg': "#3c97cb",
    '--cui-btn-active-bg': "#0e3f6a"
  }

  return (
    <Modal
      {...props}
      size="lg"
      onShow={handleShow}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{
        backgroundColor: "#0e3f6a",
        color: "white"
      }} closeButton closeVariant="white">
        <Modal.Title id="contained-modal-title-vcenter">
          Add New Faculty
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CForm className="row g-3">
          <CCol md={6}>
            <CFormInput
              type="text"
              label="First Name"
              name="first_name"
              onChange={handleChange}
              isInvalid={!!errors.first_name}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.first_name}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="Last Name"
              name="last_name"
              onChange={handleChange}
              isInvalid={!!errors.last_name}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.last_name}</CFormFeedback>
          </CCol>
          <CCol md={12}>
            <CFormInput
              type="text"
              label="Faculty Credential"
              name="credential"
              onChange={handleChange}
              isInvalid={!!errors.credential}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.credential}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="Email"
              name="email"
              onChange={handleChange}
              isInvalid={!!errors.email}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.email}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="Phone Number"
              name="phone_number"
              onChange={handleChange}
              isInvalid={!!errors.phone_number}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.phone_number}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="Address1"
              name="address1"
              onChange={handleChange}
              isInvalid={!!errors.address1}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.address1}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="Address2"
              name="address2"
              onChange={handleChange}
            />
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="City"
              name="city"
              onChange={handleChange}
              isInvalid={!!errors.city}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.city}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="State"
              name="state"
              onChange={handleChange}
              isInvalid={!!errors.state}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.state}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormSelect
              size="sm"
              className="mb-3"
              aria-label="Small select example"
              label="Country"
              name="country"
              onChange={handleChange}
              isInvalid={!!errors.country}
            >
              <option>Choose a country</option>
              {countries && countries.map((country, index) => (
                <option key={index}>
                  {country.country_name}
                </option>
              ))
              }
            </CFormSelect>
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.country}</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormInput
              type="text"
              label="Zip Code"
              name="zipcode"
              onChange={handleChange}
              isInvalid={!!errors.zipcode}
            />
            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.zipcode}</CFormFeedback>
          </CCol>

        </CForm>
      </Modal.Body>
      <Modal.Footer className="mt-3">
        <CButton style={btn} type="submit" onClick={handleSubmit} disabled={submitted}>
          Submit
        </CButton>
        <CButton onClick={props.onHide} color="secondary">Cancel</CButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AddFaculty;
