import react, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import {
    CForm,
    CCol,
    CFormInput,
    CFormSelect,
    CButton,
    CFormFeedback,
    CFormTextarea
} from "@coreui/react";
import axios from "axios";
import { CiCircleRemove } from "react-icons/ci";
import useUploadStore from "../../uploadStore/useUploadStore";

const AddNewvideo = (props) => {
    const handleShow = () => {
        setFormData({
            video_title: "",
            video_url: "",
            description: "",
            video_type: "",
            segment_type: "",
            addCourses: [],
            deleteCourses: [],
            addFaculty: [],
            deleteFaculty: [],
            addTags: [],
            deleteTags: []
        });
        setCoursesData([]),
            setFacultyData([]),
            setTagsData([])
        setFlag(false),
            setErrors({
                video_title: "",
                file: "",
                description: "",
                video_type: "",
                segment_type: "",
                addCourses: "",
                addFaculty: ""
            });
        setSubmitted(false);
    };

    const [formData, setFormData] = useState({
        video_title: "",
        video_url: "",
        description: "",
        video_type: "",
        segment_type: "",
        addCourses: [],
        deleteCourses: [],
        addFaculty: [],
        deleteFaculty: [],
        addTags: [],
        deleteTags: []
    });

    const [errors, setErrors] = useState({
        video_title: "",
        file: "",
        description: "",
        video_type: "",
        segment_type: "",
        addCourses: "",
        addFaculty: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
        setErrors((prev) => ({ ...prev, [name]: "" }));
    };

    const [tagsData, setTagsData] = useState([]);
    const [tagList, setTagList] = useState([]);

    const parseUserFromLocalStorage = () => {
        try {
            const user = localStorage.getItem("user");
            if (!user) {
                window.location.replace('/login');
                return;
            }
            const parsedUser = JSON.parse(user);
            return parsedUser;
        } catch (error) {
            console.error("Error parsing user from localStorage:", error);
            return null;
        }
    };
    const user = parseUserFromLocalStorage();
    const { token } = user;

    useEffect(() => {
        const getTagList = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-tags`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setTagList(response.data.data);

            } catch (error) {
                console.error("Error fetching tags:", error);
            }
        };
        if (user) {
            getTagList();
        }
    }, [])

    const addTag = () => {
        const newTagsData = [...tagsData, 'newTag'];
        setTagsData(newTagsData);
    };

    const removeTag = (index) => {
        const newTagsData = [...tagsData];
        newTagsData.splice(index, 1);
        setFormData((prev) => {
            const newDeleteTags = [...(prev.deleteTags || []), formData.addTags[index]];
            const newAddTags = [...(prev.addTags || [])];
            newAddTags.splice(index, 1);
            return {
                ...prev,
                deleteTags: newDeleteTags,
                addTags: newAddTags
            };
        });
        setTagsData(newTagsData);
    };

    const handleTagChange = (index, e) => {
        const selectedTagId = parseInt(e.target.value, 10);
        const selectedTag = tagList.find(tag => tag.tag_id === selectedTagId);

        setFormData((prev) => {
            const newAddTags = [...(prev.addTags || [])];

            if (selectedTag && selectedTag.tag_id) {
                newAddTags[index] = selectedTag.tag_id;
            } else {
                console.error("Selected tag is undefined or missing tag_id property for addTags");
            }

            return {
                ...prev,
                addTags: newAddTags
            };
        });
        setErrors((prev) => ({ ...prev, addTags: "" }));
    };

    const [coursesData, setCoursesData] = useState([]);
    const [courseList, setCourseList] = useState([]);

    useEffect(() => {
        const getCourseList = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-courses`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setCourseList(response.data.data);

            } catch (error) {
                console.error("Error fetching courses:", error);
            }
        };

        if (user) {
            getCourseList();
        }
    }, [])

    const addCourse = () => {
        const newCoursesData = [...coursesData, 'newCourse'];
        setCoursesData(newCoursesData);
    };

    const removeCourse = (index) => {
        const newCoursesData = [...coursesData];
        newCoursesData.splice(index, 1);
        setFormData((prev) => {
            const newDeleteCourses = [...(prev.deleteCourses || []), formData.addCourses[index]];
            const newAddCourses = [...(prev.addCourses || [])];
            newAddCourses.splice(index, 1);
            return {
                ...prev,
                deleteCourses: newDeleteCourses,
                addCourses: newAddCourses
            };
        });

        setCoursesData(newCoursesData);
    };

    const handleCourseChange = (index, e) => {
        const selectedCourseId = parseInt(e.target.value, 10);
        const selectedCourse = courseList.find(course => course.course_id === selectedCourseId);

        setFormData((prev) => {
            const newAddCourses = [...(prev.addCourses || [])];

            if (selectedCourse && selectedCourse.course_id) {
                newAddCourses[index] = selectedCourse.course_id;
            } else {
                console.error("Selected course is undefined or missing course_id property for addCourses");
            }

            return {
                ...prev,
                addCourses: newAddCourses
            };
        });
        setErrors((prev) => ({ ...prev, addCourses: "" }));
    };

    const [facultyData, setFacultyData] = useState([]);
    const [facultyList, setFacultyList] = useState([]);

    useEffect(() => {
        const getFacultyList = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-faculty`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setFacultyList(response.data.data);

            } catch (error) {
                console.error("Error fetching faculty:", error);
            }
        };
        if (user) {
            getFacultyList();
        }
    }, []);

    const addFaculty = () => {
        const newFacultyData = [...facultyData, 'newFaculty'];
        setFacultyData(newFacultyData);
    };

    const removeFaculty = (index) => {
        const newFacultyData = [...facultyData];
        newFacultyData.splice(index, 1);
        setFormData((prev) => {
            const newDeleteFaculty = [...(prev.deleteFaculty || []), formData.addFaculty[index]];
            const newAddFaculty = [...(prev.addFaculty || [])];
            newAddFaculty.splice(index, 1);
            return {
                ...prev,
                deleteFaculty: newDeleteFaculty,
                addFaculty: newAddFaculty
            };
        });

        setFacultyData(newFacultyData);
    };

    const handleFacultyChange = (index, e) => {
        const selectedFacultyId = parseInt(e.target.value, 10);
        const selectedFaculty = facultyList.find(faculty => faculty.faculty_id === selectedFacultyId);

        setFormData((prev) => {
            const newAddFaculty = [...(prev.addFaculty || [])];

            if (selectedFaculty && selectedFaculty.faculty_id) {
                newAddFaculty[index] = selectedFaculty.faculty_id;
            } else {
                console.error("Selected faculty is undefined or missing faculty_id property for addFaculty");
            }

            return {
                ...prev,
                addFaculty: newAddFaculty
            };
        });
        setErrors((prev) => ({ ...prev, addFaculty: "" }));
    };

    const [flag, setFlag] = useState(false);
    const handleAddVideoFlag = (flag) => {
        props.addVideoFlag(flag);
    }

    const [selectedFiles, setSelectedFiles] = useState([]);
    const uploadFiles = useUploadStore((state) => state.addFiles);
    const [signedURL, setSignedURL] = useState([]);

    const handleZipFileChange = async (e) => {
        try {
            const fileError = await fileValidate(e.target.files[0], null);
            setErrors((prev) => ({
                ...prev,
                file: fileError,
            }));

            const fileName = e.target.files[0].name.replace(/\.[^/.]+$/, "").replace(/\s+/g, '-');
            setFormData((prev) => ({
                ...prev,
                video_url: fileName,
            }));

            const newFiles = Array.from(e.target.files);
            setSelectedFiles(newFiles);
        } catch (error) {
            console.error("handle-zipfilechang-error", error);
        }
    };

    const isNonEmptyField = (value, fieldName) => {
        if (!value || (typeof value === 'string' && value.trim() === '')) {
            return `${fieldName} is required`;
        }
        return null;
    };

    const fileValidate = async (file, video_id) => {
        try {
            if (!file) {
                return `Please select a file`;
            } else if (!file.name.match(/\.(zip|h5p)$/i)) {
                return `Please upload a ZIP file`;
            } else {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/check-zipfile/${file.name}/${video_id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });
                if (response) {
                    setSignedURL(response.data.preSignedURL);
                    return null;
                }
            }
        } catch (error) {
            console.error("Error checking zip file:", error);
            if (error.response && error.response.status === 400) {
                return `Zip file already exists.`;
            }
        }
    };

    const isNotEmptyArray = (array, fieldName) => {
        if (!Array.isArray(array) || array.length === 0) {
            return { field: fieldName, message: `${fieldName} should not be empty` };
        }
        return null;
    };

    const checkValidation = async () => {
        const videoTitleError = isNonEmptyField(formData.video_title, 'Video Title');
        const descriptionError = isNonEmptyField(formData.description, 'Description');
        const videoTypeError = isNonEmptyField(formData.video_type, 'Video Type');
        const segmentTypeError = isNonEmptyField(formData.segment_type, 'Segment Type');
        const courseTypeError = isNotEmptyArray(formData.addCourses, 'Course');
        const facultyTypeError = isNotEmptyArray(formData.addFaculty, 'Faculty');

        setErrors({
            video_title: videoTitleError,
            description: descriptionError,
            video_type: videoTypeError,
            segment_type: segmentTypeError,
            addCourses: courseTypeError,
            addFaculty: facultyTypeError,
        });

        return (
            !videoTitleError &&
            !errors.file &&
            !descriptionError &&
            !videoTypeError &&
            !segmentTypeError &&
            !courseTypeError &&
            !facultyTypeError
        );
    };

    const [submitted, setSubmitted] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (await checkValidation()) {
                setSubmitted(true);
                axios.post(`${process.env.REACT_APP_API_BASE_URL}/add-video`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }).then(async (response) => {
                    console.log("add-video",response)
                    if (response) {
                        const filesToAdd = selectedFiles.map((file) => ({
                            file,
                            signedUrl: signedURL,
                        }));
                        await uploadFiles(filesToAdd);
                        setSelectedFiles([]);
                        setSignedURL("");
                        handleAddVideoFlag(setFlag(true));
                        props.onHide();
                    }
                }).catch((error) => {
                    console.error("error in adding video", error);
                })
            }
        } catch (err) {
            console.log("error in adding video and related tags", err);
        } finally {
            setSubmitted(false);
        }
    };

    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg': "#0e3f6a"
    }

    return (
        <>
            <Modal
                {...props}
                size="lg"
                onShow={handleShow}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header style={{
                    backgroundColor: "#0e3f6a",
                    color: "white"
                }}
                    closeButton closeVariant="white">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add New Video
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CForm className="row g-3">
                        <CCol md={12}>
                            <CFormInput
                                type="text"
                                label="Video Title"
                                name="video_title"
                                placeholder="Enter a title for the video"
                                onChange={handleChange}
                                isInvalid={!!errors.video_title}
                            />
                            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.video_title}</CFormFeedback>
                        </CCol>
                        <CCol md={12}>
                            <CFormInput type="file"
                                label="Video File"
                                name="file"
                                onChange={handleZipFileChange}
                                isInvalid={!!errors.file}
                            />
                            <CFormFeedback type="invalid" style={{ color: "red" }}>
                                {errors.file}</CFormFeedback>
                        </CCol>
                        <CCol md={6}>
                            <CFormSelect
                                size="sm"
                                className="mb-3"
                                aria-label="Small select example"
                                label="Video Type"
                                name="video_type"
                                onChange={handleChange}
                                isInvalid={!!errors.video_type}
                            >
                                <option>Choose a video type</option>
                                <option>H5P</option>
                                <option>HTML5</option>
                            </CFormSelect>
                            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.video_type}</CFormFeedback>
                        </CCol>
                        <CCol md={6}>
                            <CFormSelect
                                size="sm"
                                className="mb-3"
                                aria-label="Small select example"
                                label="Segment Type"
                                name="segment_type"
                                onChange={handleChange}
                                isInvalid={!!errors.segment_type}
                            >
                                <option>Choose a segment type</option>
                                <option>Lecture</option>
                                <option>Lab</option>
                            </CFormSelect>
                            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.segment_type}</CFormFeedback>
                        </CCol>

                        <CCol md={12}>
                            <CFormTextarea
                                rows={3}
                                label="Description"
                                name="description"
                                placeholder="Add a description for the video"
                                onChange={handleChange}
                                isInvalid={!!errors.description}
                            />
                            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.description}</CFormFeedback>
                        </CCol>

                        <div>
                            <h6 style={{ fontWeight: "normal" }}>Add Courses</h6>
                            <div style={{ marginBottom: "10px" }}>
                                <CButton
                                    onClick={addCourse}
                                    style={btn}
                                >Add</CButton>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                            }}>
                                {coursesData.map((course, index) => (
                                    <div key={index}
                                        style={{
                                            display: "flex",
                                            marginRight: "2vw",
                                            marginBottom: "10px"
                                        }}>
                                        <CCol md={12} style={{
                                            display: "flex"
                                        }}>
                                            <CFormSelect
                                                size="md"
                                                className="mb-3"
                                                aria-label="Small select example"
                                                placeholder="Add a video course"
                                                onChange={(e) => handleCourseChange(index, e)}
                                                required
                                            >
                                                <option>Choose a course</option>
                                                {courseList.map((course) => (
                                                    <option key={course.course_id} value={course.course_id}>
                                                        {course.name}
                                                    </option>
                                                ))}
                                            </CFormSelect>
                                            <span
                                                onClick={() => removeCourse(index)}
                                                style={{
                                                    color: "red",
                                                    fontSize: "30px",
                                                    backgroundColor: "transparent",
                                                    cursor: "pointer",
                                                    marginTop: "-8px"
                                                }}><CiCircleRemove /></span>
                                        </CCol>
                                    </div>
                                ))}
                            </div>
                            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.addCourses && errors.addCourses.message}</CFormFeedback>
                        </div>

                        <div>
                            <h6 style={{ fontWeight: "normal" }}>Add Faculty</h6>
                            <div style={{ marginBottom: "10px" }}>
                                <CButton
                                    onClick={addFaculty}
                                    style={btn}
                                >Add</CButton>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                            }}>
                                {facultyData.map((faculty, index) => (
                                    <div key={index}
                                        style={{
                                            display: "flex",
                                            marginRight: "2vw",
                                            marginBottom: "10px"
                                        }}>
                                        <CCol md={12} style={{
                                            display: "flex"
                                        }}>
                                            <CFormSelect
                                                size="md"
                                                className="mb-3"
                                                aria-label="Small select example"
                                                placeholder="Add a video faculty"
                                                // name="segment_type"
                                                onChange={(e) => handleFacultyChange(index, e)}
                                                required
                                            >
                                                <option>Choose a faculty</option>
                                                {facultyList.map((faculty) => (
                                                    <option key={faculty.faculty_id} value={faculty.faculty_id}>
                                                        {faculty.first_name} {faculty.last_name}
                                                    </option>
                                                ))}
                                            </CFormSelect>
                                            <span
                                                onClick={() => removeFaculty(index)}
                                                style={{
                                                    color: "red",
                                                    fontSize: "30px",
                                                    backgroundColor: "transparent",
                                                    cursor: "pointer",
                                                    marginTop: "-8px"
                                                }}><CiCircleRemove /></span>
                                        </CCol>
                                    </div>
                                ))}
                            </div>
                            <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.addFaculty && errors.addFaculty.message}</CFormFeedback>
                        </div>

                        <div>
                            <h6 style={{ fontWeight: "normal" }}>Add Tags</h6>
                            <div style={{ marginBottom: "10px" }}>
                                <CButton
                                    onClick={addTag}
                                    style={btn}
                                >Add</CButton>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                            }}>
                                {tagsData.map((tag, index) => (
                                    <div key={index}
                                        style={{
                                            display: "flex",
                                            marginRight: "2vw",
                                            marginBottom: "10px"
                                        }}>
                                        <CCol md={12} style={{
                                            display: "flex"
                                        }}>
                                            <CFormSelect
                                                size="md"
                                                className="mb-3"
                                                aria-label="Small select example"
                                                placeholder="Add a video tag"
                                                // name="segment_type"
                                                onChange={(e) => handleTagChange(index, e)}
                                                required
                                            >
                                                <option>Choose a tag</option>
                                                {tagList.map((tag) => (
                                                    <option key={tag.tag_id} value={tag.tag_id}>
                                                        {tag.tag_name}
                                                    </option>
                                                ))}
                                            </CFormSelect>
                                            <span
                                                onClick={() => removeTag(index)}
                                                style={{
                                                    color: "red",
                                                    fontSize: "30px",
                                                    backgroundColor: "transparent",
                                                    cursor: "pointer",
                                                    marginTop: "-8px"
                                                }}><CiCircleRemove /></span>
                                        </CCol>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </CForm>
                </Modal.Body>

                <Modal.Footer className="mt-3">
                    <CButton style={btn} type="submit" onClick={handleSubmit} disabled={submitted}>
                        Submit</CButton>
                    <CButton onClick={props.onHide} color="secondary">Cancel</CButton>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddNewvideo;
