import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { BiSolidHide, BiSolidShow } from 'react-icons/bi'
import axios from 'axios'

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value
      }));
    } catch (error) {
      console.log("handle-change-error", error)
    }
  }

  const parseUserFromLocalStorage = () => {
    try {
      const user = localStorage.getItem("user");
      if (!user) return null;
      const parsedUser = JSON.parse(user);
      return parsedUser;
    } catch (error) {
      console.error("Error parsing user from localStorage:", error);
      return null;
    }
  };

  useEffect(() => {
    const user = parseUserFromLocalStorage();
    const localStorageToken = user?.token;
    const userRole = user?.user_role;

    if (localStorageToken && location.pathname === '/login') {
      if (userRole === 1) navigate("/user-list")
      if (userRole == 2) navigate("/my-subscription-list1")
    };

  }, [navigate, location]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/login`, formData);

      localStorage.setItem('user', JSON.stringify(response?.data?.user));
      const user = parseUserFromLocalStorage();
      const userRole = user?.user_role;

      if (userRole === 1) {
        navigate("/user-list")
      } else if (userRole == 2)
        navigate("/my-subscription-list1")

    } catch (error) {
      console.log("Login failed", error);
      if (error.response.data.error) {
        setError(error.response.data.error);
      }
    }
  }

  const btn = {
    '--cui-btn-bg': "#0e3f6a",
    '--cui-btn-color': "white",
    '--cui-btn-hover-bg': "#3c97cb",
    '--cui-btn-active-bg': "#0e3f6a"
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit}>
                    <h2>Login</h2>
                    <p className="text-medium-emphasis">Sign In to your account</p>
                    {error && <p style={{ "color": "red" }}>{error}</p>}
                    <CInputGroup className="mb-3">
                      <CInputGroupText style={{ backgroundColor: "#0e3f6a", color: "white" }}>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        type="text"
                        name="email"
                        placeholder='Email'
                        onChange={handleChange}
                      />
                    </CInputGroup>
                    <div style={{ position: "relative", width: "100%" }}>
                      <CInputGroup className="mb-4">
                        <CInputGroupText style={{ backgroundColor: "#0e3f6a", color: "white" }}>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <CFormInput
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Password"
                          onChange={handleChange}
                          style={{ paddingRight: "2.5rem" }}
                        />
                        <span style={{
                          fontSize: "20px",
                          position: "absolute",
                          right: "1rem",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer"
                        }} onClick={togglePasswordVisibility}>
                          {showPassword ? <span><BiSolidHide /></span> : <span><BiSolidShow /></span>}
                        </span>
                      </CInputGroup>
                    </div>

                    <CRow>
                      <CCol xs={6}>
                        <CButton style={btn} className="px-4" type="submit">
                          Login
                        </CButton>
                      </CCol>
                      <CCol xs={6} className="text-right">
                        <CButton color="link" style={{ "cursor": "pointer", float: "right" }}
                          onClick={() => navigate("/forgot-password")}>
                          Forgot password?
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
