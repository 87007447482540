import react, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import {
    CForm,
    CCol,
    CFormInput,
    CFormSelect,
    CButton,
    CFormFeedback,
    CFormTextarea
} from "@coreui/react";
import axios from "axios";
import { CiCircleRemove } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

const AddProgram = (props) => {
    const navigate = useNavigate();
    const handleShow = () => {
        setFormData({
            name: "",
            description: "",
            addCourses: [],
            deleteCourses: [],
            addVideos: [],
            deleteVideos: []
        });
        setCoursesData([]);
        setVideosData([])
        setFlag(false);
        setErrors({});
        setSubmitted(false);
    };

    const [formData, setFormData] = useState({
        name: "",
        description: "",
        addCourses: [],
        deleteCourses: [],
        addVideos: [],
        deleteVideos: []
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
        setErrors((prev) => ({ ...prev, [name]: "" }));
    };

    const parseUserFromLocalStorage = () => {
        try {
            const user = localStorage.getItem("user");
            if (!user) {
                window.location.replace('/login');
                return;
            }
            const parsedUser = JSON.parse(user);
            return parsedUser;
        } catch (error) {
            console.error("Error parsing user from localStorage:", error);
            return null;
        }
    };

    const user = parseUserFromLocalStorage();
    const { token } = user;

    const [coursesData, setCoursesData] = useState([]);
    const [courseList, setCourseList] = useState()

    useEffect(() => {
        const getCourseList = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-courses`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setCourseList(response?.data?.data);

            } catch (error) {
                console.error("Error fetching courses:", error);
            }
        };
        if (user) {
            getCourseList();
        }
    }, [])

    const addCourse = () => {
        const newCoursesData = [...coursesData, 'newCourse'];
        setCoursesData(newCoursesData);
    };

    const removeCourse = (index) => {
        const newCoursesData = [...coursesData];
        newCoursesData.splice(index, 1);

        setFormData((prev) => {
            const newDeleteCourses = [...(prev.deleteCourses || []), formData.addCourses[index]];
            const newAddCourses = [...(prev.addCourses || [])];
            newAddCourses.splice(index, 1);
            return {
                ...prev,
                deleteCourses: newDeleteCourses,
                addCourses: newAddCourses
            };
        });
        setCoursesData(newCoursesData);
    };

    const handleCourseChange = (index, e) => {
        const selectedCourseId = parseInt(e.target.value, 10);
        const selectedCourse = courseList.find(course => course.course_id === selectedCourseId);

        setFormData((prev) => {
            const newAddCourses = [...(prev.addCourses || [])];
            if (selectedCourse && selectedCourse.course_id) {
                newAddCourses[index] = selectedCourse.course_id;
            } else {
                console.error("Selected course is undefined or missing course_id property for addCourse");
            }
            return {
                ...prev,
                addCourses: newAddCourses
            };
        });
        setErrors((prev) => ({ ...prev, addCourses: "" }));
    };

    const [videosData, setVideosData] = useState([]);
    const [videoList, setVideoList] = useState()

    useEffect(() => {
        const getVideoList = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-videos`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setVideoList(response.data.data);
            } catch (error) {
                console.error("Error fetching videos:", error);
            }
        };
        if (user) {
            getVideoList();
        }
    }, [])

    const addVideo = () => {
        const newVideosData = [...videosData, 'newVideo'];
        setVideosData(newVideosData);
    };

    const removeVideo = (index) => {
        const newVideosData = [...videosData];
        newVideosData.splice(index, 1);
        setFormData((prev) => {
            const newDeleteVideos = [...(prev.deleteVideos || []), formData.addVideos[index]];
            const newAddVideos = [...(prev.addVideos || [])];
            newAddVideos.splice(index, 1);
            return {
                ...prev,
                deleteVideos: newDeleteVideos,
                addVideos: newAddVideos
            };
        });

        setVideosData(newVideosData);
    };

    const handleVideoChange = (index, e) => {
        const selectedVideoId = parseInt(e.target.value, 10);
        const selectedVideo = videoList.find(video => video.video_id === selectedVideoId);

        setFormData((prev) => {
            const newAddVideos = [...(prev.addVideos || [])];

            if (selectedVideo && selectedVideo.video_id) {
                newAddVideos[index] = selectedVideo.video_id;
            } else {
                console.error("Selected video is undefined or missing video_id property for addVideos");
            }

            return {
                ...prev,
                addVideos: newAddVideos
            };
        });
        setErrors((prev) => ({ ...prev, addVideos: "" }));
    };

    const [flag, setFlag] = useState(false);
    const handleAddProgramFlag = (flag) => {
        props.addProgramFlag(flag);
    }

    const [submitted, setSubmitted] = useState(false);
    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setSubmitted(true);
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/add-program`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            handleAddProgramFlag(setFlag(true));
            props.onHide();
        } catch (err) {
            console.log("error in adding Program", err);
            if (err.response.data.errors) {
                const apiErrors = err.response.data.errors;
                const fieldErrors = {};
                Object.keys(apiErrors).forEach((field) => {
                    fieldErrors[field] = apiErrors[field].join(" ");
                });
                setErrors(fieldErrors);
            }
        } finally {
            setSubmitted(false);
        }
    };

    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg': "#0e3f6a"
    }

    return (
        <Modal
            {...props}
            size="lg"
            onShow={handleShow}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header style={{
                backgroundColor: "#0e3f6a",
                color: "white"
            }}
                closeButton closeVariant="white">
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Program
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CForm className="row g-3">
                    <CCol md={12}>
                        <CFormInput
                            type="text"
                            label="Program Name"
                            name="name"
                            placeholder="Enter a program name"
                            onChange={handleChange}
                            isInvalid={!!errors.name}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.name}</CFormFeedback>
                    </CCol>
                    <CCol md={12}>
                        <CFormTextarea
                            rows={3}
                            label="Description"
                            name="description"
                            placeholder="Add a description for the Program"
                            onChange={handleChange}
                            isInvalid={!!errors.description}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.description}</CFormFeedback>
                    </CCol>

                    {/* courses */}
                    <div>
                        <h6 style={{ fontWeight: "normal" }}>Add Courses</h6>
                        <div style={{ marginBottom: "10px" }}>
                            <CButton
                                onClick={addCourse}
                                style={btn}
                            >Add</CButton>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                        }}>
                            {coursesData.map((course, index) => (
                                <div key={index}
                                    style={{
                                        display: "flex",
                                        marginRight: "2vw",
                                        marginBottom: "10px"
                                    }}>
                                    <CCol md={12} style={{
                                        display: "flex"
                                    }}>
                                        <CFormSelect
                                            size="md"
                                            className="mb-3"
                                            aria-label="Small select example"
                                            placeholder="Add a course in Program"
                                            onChange={(e) => handleCourseChange(index, e)}
                                        >
                                            <option>Choose a course</option>
                                            {courseList.map((course) => (
                                                <option key={course.course_id} value={course.course_id}>
                                                    {course.name}</option>
                                            ))}
                                        </CFormSelect>
                                        <span
                                            onClick={() => removeCourse(index)}
                                            style={{
                                                color: "red",
                                                fontSize: "30px",
                                                backgroundColor: "transparent",
                                                cursor: "pointer",
                                                marginTop: "-8px"
                                            }}><CiCircleRemove /></span>
                                    </CCol>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* videos */}
                    <div>
                        <h6 style={{ fontWeight: "normal" }}>Add Videos</h6>
                        <div style={{ marginBottom: "10px" }}>
                            <CButton
                                onClick={addVideo}
                                style={btn}
                            >Add</CButton>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                        }}>
                            {videosData.map((video, index) => (
                                <div key={index}
                                    style={{
                                        display: "flex",
                                        marginRight: "2vw",
                                        marginBottom: "10px"
                                    }}>
                                    <CCol md={12} style={{
                                        display: "flex"
                                    }}>
                                        <CFormSelect
                                            size="md"
                                            className="mb-3"
                                            aria-label="Small select example"
                                            placeholder="Add a video in Program"
                                            onChange={(e) => handleVideoChange(index, e)}
                                        >
                                            <option>Choose a video</option>
                                            {videoList.map((video) => (
                                                <option key={video.video_id} value={video.video_id}>
                                                    {video.video_title}
                                                </option>
                                            ))}
                                        </CFormSelect>
                                        <span
                                            onClick={() => removeVideo(index)}
                                            style={{
                                                color: "red",
                                                fontSize: "30px",
                                                backgroundColor: "transparent",
                                                cursor: "pointer",
                                                marginTop: "-8px"
                                            }}><CiCircleRemove /></span>
                                    </CCol>
                                </div>
                            ))}
                        </div>
                        {errors.general ? <CFormFeedback style={{ color: "red" }}>
                            {errors.general}</CFormFeedback> : <></>}
                    </div>

                </CForm>
            </Modal.Body>
            <Modal.Footer className="mt-3">
                <CButton style={btn} type="submit" onClick={handleSubmit} disabled={submitted}>
                    Submit
                </CButton>
                <CButton onClick={props.onHide} color="secondary">Cancel</CButton>
            </Modal.Footer>
        </Modal>
    );
};

export default AddProgram;
