import react, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import {
    CForm,
    CCol,
    CFormInput,
    CFormSelect,
    CButton,
    CFormFeedback,
} from "@coreui/react";
import axios from "axios";

const EditTag = (props) => {
    const handleShow = () => {
        setErrors({});
    };

    const tagData = props?.tag;

    const tagTypeMapping = {
        1: "Faculty",
        2: "Course",
        3: "Others"
    }

    const tagTypeValue = tagData ? tagData.tag_type : '';
    const tagTypeName = tagTypeMapping[tagTypeValue];

    const initialFormData = {
        tag_name: tagData ? tagData.tag_name : "",
        tag_type: tagTypeName || "",
    };

    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setFormData(initialFormData);
    }, [tagData])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
        setErrors((prev) => ({ ...prev, [name]: "" }));
    };

    const [flag, setFlag] = useState(false);
    const handleEditTagFlag = (flag) => {
        flag = true;
        props.editTagFlag(flag);
    }

    const parseUserFromLocalStorage = () => {
        try {
            const user = localStorage.getItem("user");
            if (!user) {
                window.location.replace('/login');
                return;
            }
            const parsedUser = JSON.parse(user);
            return parsedUser;
        } catch (error) {
            console.error("Error parsing user from localStorage:", error);
            return null;
        }
    };
    const user = parseUserFromLocalStorage();
    const { token } = user;

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            await axios.put(`${process.env.REACT_APP_API_BASE_URL}/update-tags/${tagData.tag_id}`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            handleEditTagFlag(setFlag(true));
            props.onHide();
        } catch (err) {
            console.log("error in editing tag details", err);
            if (err.response.data.errors) {
                const apiErrors = err.response.data.errors;
                const fieldErrors = {};
                Object.keys(apiErrors).forEach((field) => {
                    fieldErrors[field] = apiErrors[field].join(" ");
                });
                setErrors(fieldErrors);
            }
        }
    };

    const btn = {
        '--cui-btn-bg': "#0e3f6a",
        '--cui-btn-color': "white",
        '--cui-btn-hover-bg': "#3c97cb",
        '--cui-btn-active-bg':"#0e3f6a"
      }

    return (
        <Modal
            {...props}
            size="lg"
            onShow={handleShow}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header style={{
                backgroundColor: "#0e3f6a",
                color: "white"
            }}
                closeButton closeVariant="white">
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CForm className="row g-3">
                    <CCol md={12}>
                        <CFormInput
                            type="text"
                            label="Tag Name"
                            name="tag_name"
                            value={formData.tag_name}
                            onChange={handleChange}
                            isInvalid={!!errors.tag_name}
                        />
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.tag_name}</CFormFeedback>
                    </CCol>
                    <CCol md={12}>
                        <CFormSelect
                            size="sm"
                            className="mb-3"
                            aria-label="Small select example"
                            label="Tag Type"
                            name="tag_type"
                            value={formData.tag_type}
                            onChange={handleChange}
                            isInvalid={!!errors.tag_type}
                        >
                            <option>Choose tag type</option>
                            <option>Faculty</option>
                            <option>Course</option>
                            <option>Others</option>
                        </CFormSelect>
                        <CFormFeedback type="invalid" style={{ color: "red" }}>{errors.tag_type}</CFormFeedback>
                    </CCol>
                </CForm>
            </Modal.Body>
            <Modal.Footer>
                <CButton style={btn} type="submit" onClick={handleSubmit}>
                    Submit
                </CButton>
                <CButton onClick={props.onHide} color="secondary">Cancel</CButton>
            </Modal.Footer>
        </Modal>
    );
};

export default EditTag;
